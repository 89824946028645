export const englishMonths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const englishLeapMonths = [
    31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
];
export const nepaliMonths = [
    // [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30], // 1971
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    // [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    // [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 31, 32, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    // [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
    // [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    // [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    // [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    // [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    // [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    // [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    // [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    // [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    // [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31], //2000
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30], //2001
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30], // 2002
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31], // 2003
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31], // 2004
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30], // 2005
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30], // 2006
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31], // 2006
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31], // 2007
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30], // 2008
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30], // 2009
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31], // 2010
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 32, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [30, 32, 31, 32, 31, 31, 29, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31],
    [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30], //2071
    [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30], //2072
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31], //2073
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30], // 2076
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],
    [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30], // 2078
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30], // 2079
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30],// 2080
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31],// 2081
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
    [30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30], //2090
    [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30],
    [30, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 31, 30, 29, 30, 30, 30, 30],
    [30, 31, 32, 32, 31, 30, 30, 29, 30, 29, 30, 30],
    [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30],
    [31, 31, 32, 31, 31, 31, 29, 30, 29, 30, 29, 31],
    [31, 31, 32, 31, 31, 31, 30, 29, 29, 30, 30, 30], //2099
];
