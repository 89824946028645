<div
    #addFromDifferentTenantModal="bs-modal"
    [config]="{ backdrop: 'static' }"
    appBsModal
    aria-hidden="true"
    aria-labelledby="myLargeModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #AddFromDifferentTenantModalForm="ngForm" (ngSubmit)="save()" *ngIf="active">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{ 'AddFriendFromDifferentTenant' | localize }}</span>
                    </h5>
                    <button (click)="close()" [attr.aria-label]="l('Close')" class="btn-close" type="button"></button>
                </div>
                <div class="modal-body">
                    <div *ngIf="this.tenantToHostChatAllowed" class="row mb-5">
                        <label class="col-4 form-label">{{ 'IsHostUser' | localize }}</label>
                        <div class="col">
                            <label class="form-check form-check-custom form-check-solid form-switch py-1">
                                <input
                                        (change)="switchTenant()"
                                        [(ngModel)]="isHostUser"
                                        [disabled]="!tenantToTenantChatAllowed"
                                        class="form-check-input"
                                        name="IsHostUser"
                                        type="checkbox"
                                />
                            </label>
                        </div>
                    </div>
                    <div *ngIf="!isHostUser && tenantToTenantChatAllowed" class="row mb-5">
                        <label class="col-4 form-label">
                            <label>{{ "TenancyName" | localize }}</label>
                        </label>
                        <label class="col form-label">
                            <input
                                    [(ngModel)]="tenancyName"
                                    class="form-control"
                                    id="TenancyName"
                                    name="TenancyName"
                                    required
                                    type="text"
                            />
                        </label>
                    </div>

                    <div class="row mb-5">
                        <label class="col-4 form-label">
                            <label>{{ "UserName" | localize }}</label>
                        </label>
                        <label class="col form-label">
                            <input [(ngModel)]="userName"
                                   class="form-control"
                                   id="UserName"
                                   name="UserName"
                                   required
                                   type="text">
                        </label>
                    </div>
                </div>

                <div class="modal-footer">
                    <button (click)="close()" class="btn btn-light-primary fw-bold" type="button">
                        {{ 'Close' | localize }}
                    </button>
                    <button
                            [disabled]="!AddFromDifferentTenantModalForm.form.valid || saving"
                            class="btn btn-primary"
                            type="submit"
                    >
                        <i class="fa fa-save"></i>
                        <span>{{ 'Save' | localize }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
