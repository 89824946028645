import {PermissionCheckerService} from 'abp-ng2-module';
import {AppSessionService} from '@shared/common/session/app-session.service';

import {Injectable} from '@angular/core';
import {AppMenu} from './app-menu';
import {AppMenuItem} from './app-menu-item';

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {
    }

    //   STUDENT SIDE NAVIGATIONS
    getMenu(): AppMenu {


        //   ADMIN SIDE NAVIGATIONS
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem(
                'Dashboard',
                'Pages.Administration.Host.Dashboard',
                'fa-duotone fa-house',
                '/app/admin/hostDashboard'
            ),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'fa-duotone fa-house', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'pi pi-users', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'pi pi-book', '/app/admin/editions'),
            new AppMenuItem(
                'Accounting',
                'Pages.Accounting',
                'fa-duotone fa-money-check-dollar-pen',
                '',
                [],
                [
                    new AppMenuItem(
                        'AccountGroups',
                        'Pages.AccountGroups',
                        'fa-duotone fa-users',
                        '/app/main/accounting/accountGroups'
                    ),
                    new AppMenuItem(
                        'AccountLedgers',
                        'Pages.AccountLedgers',
                        'fa-duotone fa-receipt',
                        '/app/main/accounting/accountLedgers'
                    ),
                    new AppMenuItem(
                        'FinancialYears',
                        'Pages.FinancialYears',
                        'fa-duotone fa-calendar-days',
                        '/app/main/accounting/financialYears'
                    ),
                    new AppMenuItem(
                        'VoucherType',
                        'Pages.VoucherTypes',
                        'fa-duotone fa-receipt',
                        '/app/main/generalSetting/voucherTypes'
                    ),
                    new AppMenuItem(
                        'MergeLedger',
                        'Pages.VoucherTypes',
                        'fa-duotone fa-file-circle-plus',
                        '/app/main/accounting/mergeLedger'
                    ),
                    new AppMenuItem(
                        'DocumentNumering',
                        'Pages.VoucherTypes',
                        'fa-duotone fa-input-numeric',
                        '/app/main/generalSetting/documentNumering'
                    ),

                    new AppMenuItem(
                        'Transaction',
                        '',
                        'fa-duotone fa-money-bill-transfer',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'ContraMasters',
                                '',
                                'fa-duotone fa-building-columns',
                                '/app/main/transaction/contraMasters'
                            ),
                            new AppMenuItem(
                                'PaymentMasters',
                                'Pages.PaymentMasters',
                                'fa-duotone fa-sack-dollar',
                                '/app/main/transaction/paymentMasters'
                            ),
                            new AppMenuItem(
                                'ReceiptMasters',
                                'Pages.ReceiptMasters',
                                'fa-duotone fa-receipt',
                                '/app/main/transaction/receiptMaster'
                            ),
                            new AppMenuItem(
                                'JournalMasters',
                                'Pages.JournalMasters',
                                'fa-duotone fa-book-open',
                                '/app/main/transaction/journalMasters'
                            ),
                            new AppMenuItem(
                                'BankReconciliation',
                                'Pages.BankReconciliations',
                                'fa-duotone fa-piggy-bank',
                                '/app/main/transaction/bankReconciliation'
                            ),
                            new AppMenuItem('LoanMaster', 'Pages.LoanMaster', 'pi pi-list', '/app/main/transaction/loanMaster'),
                            new AppMenuItem(
                                'PdcPayable',
                                'Pages.PdcPayables',
                                'fa-duotone fa-hand-holding-dollar',
                                '/app/main/transaction/PdcPayable'
                            ),
                            new AppMenuItem(
                                'PdcReceivable',
                                'Pages.PdcReceivables',
                                'fa-duotone fa-hands-holding-dollar',
                                '/app/main/transaction/PdcReceivable'
                            ),
                            new AppMenuItem(
                                'PdcClearance',
                                'Pages.PdcClearances',
                                'fa-duotone fa-circle-check',
                                '/app/main/transaction/PdcClearance'
                            ),
                        ]
                    ),
                ]
            ),

            new AppMenuItem(
                'Chamber',
                '',
                'fa-duotone fa-building-columns',
                '',
                [],
                [
                    new AppMenuItem('FirmType', 'Pages.FirmTypes', 'fa-duotone fa-user-plus', '/app/main/chamber/FirmTypes'),
                    new AppMenuItem('FeeHead', 'Pages.FeeHeads', 'fa-duotone fa-user-plus', '/app/main/chamber/feehead'),
                    new AppMenuItem('FeeStructure', 'Pages.FeeStructures', 'fa-duotone fa-money-check-dollar-pen', '/app/main/chamber/feestructure'),
                    new AppMenuItem('FirmCategory', 'Pages.FirmCategories', 'fa-duotone fa-cubes-stacked', '/app/main/chamber/firmcategories'),
                    new AppMenuItem('Firm', 'Pages.Firms', 'fa-duotone fa-buildings', '/app/main/chamber/firm'),
                    new AppMenuItem('Firm InActive', 'Pages.ChamberStatuses', 'fa-duotone fa-buildings', '/app/main/chamber/firmInActive'),
                    new AppMenuItem('Non-Firm', 'Pages.NoneFirms', 'fa-duotone fa-building', '/app/main/chamber/noneFirm'),
                    new AppMenuItem('FeeCollection', 'Pages.FeeCollections', 'fa-duotone fa-envelope-open-dollar', '/app/main/chamber/feecollection'),
                    new AppMenuItem('Non-FirmFeeCollection', 'Pages.FeeCollections', 'fa-duotone fa-box-dollar', '/app/main/chamber/noneFirmFee'),
                    new AppMenuItem('FirmRenew', 'Pages.FirmRenews', 'fa-duotone fa-arrows-rotate', '/app/main/chamber/firmRenew'),
                    new AppMenuItem('CapitalEvaluation', 'Pages.CapitalEvaluations', 'fa-duotone fa-sack-dollar', '/app/main/chamber/capitalEvaluation'),
                    new AppMenuItem('Recommendation', 'Pages.CapitalEvaluations', 'fa-duotone fa-thumbs-up', '/app/main/chamber/recomendation'),

                    new AppMenuItem(
                        'ChamberReports',
                        '',
                        'fa-duotone fa-chart-waterfall',
                        '',
                        [],
                        [
                            new AppMenuItem('FirmReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-simple', '/app/main/chamber/firmReport'),
                            new AppMenuItem('FirmMemberReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-user', '/app/main/chamber/firmMembers'),
                            new AppMenuItem(
                                'FeeCollectionReport',
                                'Pages.CapitalEvaluations',
                                'fa-duotone fa-chart-scatter-bubble',
                                '/app/main/chamber/feeCollectionReport'
                            ),
                            new AppMenuItem('FirmWiseFeeCollection', 'Pages.CapitalEvaluations', 'fa-duotone fa-envelope-open-dollar', '/app/main/chamber/firmWiseFeeCollectionReport'),
                            new AppMenuItem('FeeDayBookReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-line-up', '/app/main/chamber/feeDaybookReport'),
                            new AppMenuItem('FeeSummaryReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-line-up', '/app/main/chamber/feeSummaryReport'),
                            new AppMenuItem('FirmRenewReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-line', '/app/main/chamber/firmRenewReport'),
                            new AppMenuItem('RenewReport', 'Pages.CapitalEvaluations', 'fa-duotone fa-chart-column', '/app/main/chamber/renewFirmReport'),
                            new AppMenuItem('Non-FirmReport', 'Pages.NonFirmReport', 'fa-duotone fa-chart-gantt', '/app/main/chamber/nonFirmReport'),

                            // new AppMenuItem('Recommendation', 'Pages.Recommendation', 'fa-duotone fa-thumbs-up', '/app/main/chamber/recomendation'),

                            // new AppMenuItem(
                            //     'ChamberReports',
                            //     '',
                            //     'fa-duotone fa-chart-waterfall',
                            //     '',
                            //     [],
                            //     [
                            //         new AppMenuItem('FirmReport', 'Pages.FirmReport', 'fa-duotone fa-chart-simple', '/app/main/chamber/firmReport'),
                            //         new AppMenuItem('FirmMemberReport', 'Pages.FirmMembershipReport', 'fa-duotone fa-chart-user', '/app/main/chamber/firmMembers'),
                            //         new AppMenuItem(
                            //             'FeeCollectionReport',
                            //             'Pages.FeeCollectionReport',
                            //             'fa-duotone fa-chart-scatter-bubble',
                            //             '/app/main/chamber/feeCollectionReport'
                            //         ),
                            //         new AppMenuItem('FirmWiseFeeCollection', 'Pages.FirmRenewReport', 'fa-duotone fa-envelope-open-dollar', '/app/main/chamber/firmWiseFeeCollectionReport'),
                            //         new AppMenuItem('FeeDayBookReport', 'Pages.FirmRenewReport', 'fa-duotone fa-chart-line-up', '/app/main/chamber/feeDaybookReport'),
                            //         new AppMenuItem('FeeSummaryReport', 'Pages.FirmRenewReport', 'fa-duotone fa-chart-line-up', '/app/main/chamber/feeSummaryReport'),
                            //         new AppMenuItem('FirmRenewReport', 'Pages.FirmRenewReport', 'fa-duotone fa-chart-line', '/app/main/chamber/firmRenewReport'),
                            //         new AppMenuItem('RenewReport', 'Pages.FirmRenewReport', 'fa-duotone fa-chart-column', '/app/main/chamber/renewFirmReport'),
                            //         new AppMenuItem('Non-FirmReport', 'Pages.NonFirmReport', 'fa-duotone fa-chart-gantt', '/app/main/chamber/nonFirmReport'),

                        ]
                    ),
                ]
            ),
            new AppMenuItem(
                'Payroll',
                '',
                'fa-duotone fa-hand-holding-dollar',
                '',
                [],
                [
                    new AppMenuItem(
                        'Departments',
                        'Pages.Departments',
                        'fa-duotone fa-building-columns',
                        '/app/main/controlPanel/departments'
                    ),
                    new AppMenuItem(
                        'Designations',
                        'Pages.Designations',
                        'fa-duotone fa-user-tie',
                        '/app/main/controlPanel/designations'
                    ),
                    new AppMenuItem('PayHeads', 'Pages.PayHeads', 'fa-duotone fa-user-check', '/app/main/payroll/payHeads'),
                    new AppMenuItem('WorkUnit', 'Pages.WorkUnits', 'fa-duotone fa-screen-users', '/app/main/payroll/workUnit'),
                    new AppMenuItem(
                        'EmployeeTaxSetting',
                        'Pages.EmployeeTaxSettings',
                        'fa-duotone fa-credit-card',
                        '/app/main/payroll/employeeTaxSetting'
                    ),
                    new AppMenuItem('Employees', 'Pages.Employees', 'fa-duotone fa-users', '/app/main/controlPanel/employees'),
                    new AppMenuItem(
                        'EmployeesCalender',
                        'Pages.Employees',
                        'fa-duotone fa-calendar-days',
                        '/app/main/payroll/employeeCalendar'
                    ),
                    new AppMenuItem(
                        'EmployeeOffice',
                        'Pages.AttendanceMasters',
                        'fa-duotone fa-buildings',
                        '/app/main/payroll/EmployeeOffice'
                    ),
                    new AppMenuItem(
                        'StaffAttendance',
                        'Pages.AttendanceMasters',
                        'fa-duotone fa-rainbow',
                        '/app/main/payroll/staffAttendance'
                    ),
                    new AppMenuItem(
                        'BonusDeduction',
                        'Pages.EmployeeBonusDeductions',
                        'fa-duotone fa-circle-minus',
                        '/app/main/payroll/BonusDeduction'
                    ),
                    new AppMenuItem(
                        'AdvancePayment',
                        'Pages.EmployeeLoanAndAdvances',
                        'fa-duotone fa-dollar-sign',
                        '/app/main/payroll/advancePayment'
                    ),
                    new AppMenuItem(
                        'LopDeduction',
                        'Pages.EmployeeLopDeductionMasters',
                        'fa-duotone fa-user-minus',
                        '/app/main/payroll/lopDeduction'
                    ),
                    new AppMenuItem(
                        'EmployeeOvertime',
                        'Pages.EmployeeOverTimes',
                        'fa-duotone fa-user-clock',
                        '/app/main/payroll/EmployeeOvertime'
                    ),
                    new AppMenuItem(
                        'MonthlySalary',
                        'Pages.EmployeeSalaryVoucherMasters',
                        'fa-duotone fa-money-bills',
                        '/app/main/payroll/monthlySalary'
                    ),
                    new AppMenuItem('PaySlips', 'Pages.PayHeads', 'fa-duotone fa-memo', '/app/main/payroll/paySlip'),
                    new AppMenuItem(
                        'OnlineMeeting',
                        'Pages.OnlineMeetings',
                        'fa-duotone fa-building-columns',
                        '/app/main/payroll/onlinemeeting'
                    ),
                ]
            ),
            new AppMenuItem(
                'Report',
                'Pages.Reporting',
                'fa-duotone fa-chart-simple',
                '',
                [],

                [
                    new AppMenuItem(
                        'FinancialStatement',
                        'Pages.FinancialStatement',
                        'fa-duotone fa-chart-mixed',
                        '',
                        [],
                        [
                            new AppMenuItem(
                                'TrialBalance',
                                'Pages.TrialBalanceReport',
                                'fa-duotone fa-receipt',
                                '/app/reports/trial-balance'
                            ),
                            new AppMenuItem(
                                'ProfitandLoss',
                                'Pages.ProfitAndLossReport',
                                'fa-duotone fa-arrow-down-arrow-up',
                                '/app/reports/profit-loss'
                            ),
                            new AppMenuItem(
                                'BalanceSheet',
                                'Pages.PagesBalanceSheetReport',
                                'fa-duotone fa-memo-pad',
                                '/app/reports/balance-sheet'
                            ),
                            new AppMenuItem(
                                'CashFlow',
                                'Pages.PagesCashFlowReport',
                                'fa-duotone fa-money-bill-transfer',
                                '/app/reports/cash-flow'
                            ),
                        ]
                    ),


                    new AppMenuItem(
                        'Accounting Report',
                        'Pages.Lunchs',
                        'fa-duotone fa-utensils',
                        '',

                        [],
                        [
                            new AppMenuItem(
                                'AccountGroup',
                                'Pages.AccountGroupReport',
                                'fa-duotone fa-user-group',
                                '/app/reports/account-group'
                            ),
                            new AppMenuItem(
                                'AccountLedger',
                                'Pages.AccountLedgerReport',
                                'fa-duotone fa-memo',
                                '/app/reports/account-ledger-report'
                            ),

                            new AppMenuItem(
                                'CashBankBook',
                                'Pages.CashBankBookReport',
                                'fa-duotone fa-book',
                                '/app/reports/cash-bank'
                            ),
                            new AppMenuItem('Cheque', 'Pages.ChequeReport', 'fa-duotone fa-money-check-pen', '/app/reports/cheque'),
                            new AppMenuItem(
                                'DayBook',
                                'Pages.DayBookReport',
                                'fa-duotone fa-book-open-cover',
                                '/app/reports/day-book'
                            ),

                            new AppMenuItem(
                                'ReceiptVoucher',
                                'Pages.ReceiptVoucherReport',
                                'fa-duotone fa-receipt',
                                '/app/reports/receipt-voucher'
                            ),
                            new AppMenuItem('ContraMasters', '', 'fa-duotone fa-bank', '/app/reports/contra-master'),
                            new AppMenuItem(
                                'JournalMasters',
                                'Pages.JournalMasters',
                                'fa-duotone fa-newspaper',
                                '/app/reports/journalMaster'
                            ),
                            new AppMenuItem(
                                'PdcReceivable',
                                'Pages.ReceiptMasters',
                                'fa-duotone fa-circle-down',
                                '/app/reports/PdcReceivable'
                            ),
                            new AppMenuItem(
                                'PdcClearance',
                                'Pages.PdcClearanceReport',
                                'fa-duotone fa-circle-check',
                                '/app/reports/PdcClearance'
                            ),
                            new AppMenuItem(
                                'PdcPayable',
                                'Pages.PdcPayableReport',
                                'fa-duotone fa-circle-up',
                                '/app/reports/pdc-payable'
                            ),


                        ]
                    ),
                    new AppMenuItem(
                        'Fee DayBook',
                        'Pages.AssignmentCategories',
                        'fa-duotone fa-boxes-stacked',
                        '/app/reports/fee-report/fee-dayBook'
                    ),
                ]
            ),

            new AppMenuItem(
                'Setting',
                '',
                'fa-duotone fa-gear',
                '',
                [],
                [

                    new AppMenuItem('Chamber Office', 'Pages.Branchs', 'fa-duotone fa-buildings', '/app/main/controlPanel/branchs'),
                    new AppMenuItem('Address', 'Pages.Branchs', 'fa-duotone fa-location-dot', '/app/main/controlPanel/address'),
                    new AppMenuItem(
                        'OrganizationUnits',
                        'Pages.Administration.OrganizationUnits',
                        'fa-duotone fa-house-blank',
                        '/app/admin/organization-units'
                    ),
                    new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fa-duotone fa-person', '/app/admin/roles'),
                    new AppMenuItem('Users', 'Pages.Administration.Users', 'fa-duotone fa-users', '/app/admin/users'),
                    new AppMenuItem(
                        'Languages',
                        'Pages.Administration.Languages',
                        'fa-duotone fa-language',
                        '/app/admin/languages',
                        ['/app/admin/languages/{name}/texts']
                    ),
                    new AppMenuItem(
                        'AuditLogs',
                        'Pages.Administration.AuditLogs',
                        'fa-duotone fa-list-check',
                        '/app/admin/auditLogs'
                    ),
                    new AppMenuItem(
                        'Maintenance',
                        'Pages.Administration.Host.Maintenance',
                        'fa-duotone fa-sliders',
                        '/app/admin/maintenance'
                    ),
                    new AppMenuItem(
                        'Subscription',
                        'Pages.Administration.Tenant.SubscriptionManagement',
                        'fa-duotone fa-hand-pointer',
                        '/app/admin/subscription-management'
                    ),
                    new AppMenuItem(
                        'VisualSettings',
                        'Pages.Administration.UiCustomization',
                        'fa-duotone fa-eye',
                        '/app/admin/ui-customization'
                    ),
                    new AppMenuItem(
                        'WebhookSubscriptions',
                        'Pages.Administration.WebhookSubscription',
                        'fa-duotone fa-bell',
                        '/app/admin/webhook-subscriptions'
                    ),
                    new AppMenuItem(
                        'DynamicProperties',
                        'Pages.Administration.DynamicProperties',
                        'fa-duotone fa-star',
                        '/app/admin/dynamic-property'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Host.Settings',
                        'fa-duotone fa-gear',
                        '/app/admin/hostSettings'
                    ),
                    new AppMenuItem(
                        'Settings',
                        'Pages.Administration.Tenant.Settings',
                        'fa-duotone fa-gear',
                        '/app/admin/tenantSettings'
                    ),
                ]
            ),
        ]);

    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
